import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Action from '../components/Action';
export const _frontmatter = {
  "title": "Try Firefox for Layout",
  "date": "2017-11-22",
  "promo": "grids",
  "description": "Why you should switch to Firefox for layout workflow.",
  "color": "#00e7e9"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Frontend development has multiplied in complexity and required skill level in recent years. I haven't been called a `}<em parentName="p">{`script kiddie`}</em>{` or a `}<em parentName="p">{`pixel pusher`}</em>{` in ages! (and I kind of miss it TBH). But that doesn't mean I don't still get a smug look on my face when I watch my backend friends try to center something in CSS. `}</p>
    <p>{`The kinds of experiences we're building on the web these days are way more advanced, interactive, and awesome than ever before. We've gotten extremely lucky that a brand new powerhouse spec like `}<a parentName="p" {...{
        "href": "/post/grid-beats-flexbox-full-page-layout/"
      }}>{`CSS Grid`}</a>{` arrived just in time. But our browsers' layout dev tools haven't kept pace. They've felt pretty much the same as they did five years ago - long overdue for an upgrade. `}</p>
    <p>{`Until now.`}</p>
    <h2>{`Firefox Quantum`}</h2>
    <p>{`Mozilla Firefox is making a comeback and starting to win back us UI devs' hearts. First they championed a new language called `}<a parentName="p" {...{
        "href": "https://www.rust-lang.org/en-US/"
      }}>{`Rust`}</a>{` which is similar to C++ but with improved memory and (in some cases) performance. Then they wrote a completely greenfield, experimental browser engine named `}<a parentName="p" {...{
        "href": "https://servo.org/"
      }}>{`Servo`}</a>{` from the ground up using Rust. Now finally that research is paying off, and Mozilla is `}<a parentName="p" {...{
        "href": "https://hacks.mozilla.org/2017/11/entering-the-quantum-era-how-firefox-got-fast-again-and-where-its-going-to-get-faster/"
      }}>{`replacing several major pieces of Firefox`}</a>{` with the crazy fast Servo/Rust counterparts. `}</p>
    <p>{`Quantum CSS - code named Stylo - parallelizes the computation of CSS across multiple CPU cores.`}</p>
    <p>{`Quantum Render (landing soon) will make Firefox take full advantage of that dope multi-core graphics card sitting in your computer.`}</p>
    <p>{`Quantum Compositor brings crash stability and enhanced responsiveness to Firefox.`}</p>
    <p>{`Quantum DOM prioritizes tasks that run on the main thread, making sure your app's event listeners for example run immediately and don't take a back seat to a scheduled garbage collection task. `}</p>
    <p>{`What this all means for us is that people using our apps in Firefox will get an experience that's `}<a parentName="p" {...{
        "href": "https://hacks.mozilla.org/2017/09/firefox-quantum-developer-edition-fastest-firefox-ever/"
      }}>{`twice as fast`}</a>{`.`}</p>
    <p>{`But my favorite part is that the `}<strong parentName="p">{`developer experience`}</strong>{` just got a major shot of adrenaline.`}</p>
    <h2>{`Lightning Fast Refresh`}</h2>
    <p>{`I was working on my `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` layout with the latest Firefox the other day and I have webpack setup to refresh my browser on every save. I would make a change, hit save, and Firefox would reload sooo fast I thought it was a bug - that it had done a quick glitch but not a full reload. Imagine my delight when I realized it actually `}<em parentName="p">{`had`}</em>{` reloaded, there in front of me were all of my new changes, but it had done it in a blink of an eye instead of the several seconds I'd become accustomed to.`}</p>
    <h2>{`Modern Layout Inspectors`}</h2>
    <p>{`Not only is the speed blazing, but the new layout tools are incredible. It has a fantastic `}<a parentName="p" {...{
        "href": "https://gedd.ski/post/invisible-grid/"
      }}>{`CSS Grid Inspector`}</a>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/gc/conversation2.jpg",
        "alt": "firefox grid inspector"
      }}></img></p>
    <p>{`And the team is working on a flexbox inspector as well. They're letting me beta test it so I'll write more on it as it comes out.`}</p>
    <h2>{`Animation Tools`}</h2>
    <p>{`Firefox has some sick new animation tools that let you speed up, slow down, visualize and debug your animations.`}<br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "/img/firefox-animation-tools.jpg",
        "alt": "firefox animation tool"
      }}></img></p>
    <h2>{`Clip Path Tool`}</h2>
    <p>{`When I made the `}<a parentName="p" {...{
        "href": "/build/overwatch-hero-picker/"
      }}>{`Overwatch UI`}</a>{` `}<em parentName="p">{`happy little divs`}</em>{` video, I had to use an external website to generate my CSS clip-path shapes. Now Firefox has a built in clip-path tool right in the devtools, which makes working with clip-path `}<em parentName="p">{`so`}</em>{` much better.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/clip-path-inspector.jpg",
        "alt": "firefox css clip path inspector"
      }}></img></p>
    <h2>{`Three Panels`}</h2>
    <p>{`The latest Firefox devtools now lets you have three panels open at a time. This is unbelievably nice: I can see the element I'm inspecting, it's CSS rules, and inspect its grid all at once. Can I get a hallelujah?!`}</p>
    <p><img parentName="p" {...{
        "src": "/img/three-panels.jpg",
        "alt": "three panels in devtools"
      }}></img></p>
    <h2>{`Sweet, Sweet Polish`}</h2>
    <p>{`Firefox has a bunch of other little goodies, like underlining which font is actually being rendered:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/used-font.jpg",
        "alt": "underlines used font"
      }}></img></p>
    <p>{`And a `}<a parentName="p" {...{
        "href": "https://hacks.mozilla.org/2017/09/developer-edition-devtools-update-now-with-photon-ui/"
      }}>{`bunch of other great updates`}</a>{`. I'm an absolute sucker for `}<a parentName="p" {...{
        "href": "/post/polish/"
      }}>{`polish`}</a>{` like this.`}</p>
    <p>{`I've switched to `}<a parentName="p" {...{
        "href": "https://www.mozilla.org/en-US/firefox/developer/"
      }}>{`Firefox`}</a>{` for my layout workflow and am loving it. I recommend you do the same. I still use Chrome for heavy JS/debugging work, but I'm told by the Firefox team that they're focusing on that area next. `}</p>
    <p>{`I'm eager to see what else comes from this Firefox comeback, and am incredibly happy to finally get such a solid upgrade to my layout life. `}</p>
    <p>{`Go Mozilla!`}</p>
    <Action link="https://www.mozilla.org/en-US/firefox/developer/" mdxType="Action">Try the new Firefox</Action>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      